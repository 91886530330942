
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { defineComponent } from 'vue';
import { uploadHouseHold } from '@/apis/ApiEkyc';
import constRouter from '@/constants/constRouter';
import { Lead } from '@/apis/ApiLeadGen';
import { mapGetters } from 'vuex';
import { required } from '@/helpers/validate';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { getContractId } from '@/helpers/localStorage';
export default defineComponent({
  components: { DefaultLayout, BaseButton, BaseTextError },
  name: 'BorrowerFamilyRegister',
  data() {
    return {
      imageCover: '',
      imageOwner: '',
      imageHouseHold: '',
      errorCover: '',
      errorOwner: '',
      errorHouseHold: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getExigency: 'getExigency',
      getUser: 'getUser',
      campaign: 'getCampaign',
    }),
  },
  created() {
    this.imageCover = this.getUser.houseHoldCoverImageUrl;
    this.imageOwner = this.getUser.houseHoldOwnerImageUrl;
    this.imageHouseHold = this.getUser.houseHoldImageUrl;
  },
  methods: {
    onFileChange(e: any) {
      const name = e.target.name;
      const file = e.target.files[0];
      if (name === 'houseHoldCover') {
        this.imageCover = URL.createObjectURL(file);
      }
      if (name === 'houseHoldOwner') {
        this.imageOwner = URL.createObjectURL(file);
      }
      if (name === 'houseHold') {
        this.imageHouseHold = URL.createObjectURL(file);
      }
    },
    validation() {
      this.errorCover = required('HouseHoldCover', this.imageCover);
      this.errorOwner = required('HouseHoldOwner', this.imageOwner);
      if (!this.imageCover || !this.imageOwner) {
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        const form: any = document.getElementById('form-household');
        const formdata = new FormData(form);
        this.isLoading = true;
        const { utmSource, utmCampaign, utmContent, clickId } = this.campaign;
        if (
          this.getUser.houseHoldCoverImageUrl &&
          this.getUser.houseHoldOwnerImageUrl
        ) {
          Lead({
            productName: 'easyLoan',
            contractId: this.getExigency.contractId || getContractId(),
            utmSource,
            utmCampaign,
            utmContent,
            clickId,
          });
          this.$router.push({
            name: constRouter.REGISTER_SUCCESS.name,
          });
        } else {
          uploadHouseHold(formdata)
            .then(() => {
              this.isLoading = false;
              Lead({
                productName: 'easyLoan',
                contractId: this.getExigency.contractId || getContractId(),
                utmSource,
                utmCampaign,
                utmContent,
                clickId,
              });
              this.$router.push({
                name: constRouter.REGISTER_SUCCESS.name,
              });
            })
            .catch(err => {
              this.isLoading = false;
              console.log(err);
            });
        }
      }
    },
  },
});
