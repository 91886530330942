<template>
  <default-layout>
    <div class="borrower-family-register mt-5 px-6">
      <form method="POST" @submit="handleSubmit" id="form-household">
        <div class="ImageCover">
          <p class="mt-3 font-bold required">Ảnh bìa hộ khẩu</p>
          <p>(Có ghi số hộ khẩu)</p>
          <label for="upload-photo-cover">
            <div
              class="image-ID border border-dashed border-tertiary bg-secondary"
            >
              <img class="preview-image" id="image-cover" :src="imageCover" />
              <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
                <img
                  src="@/assets/images/camera.png"
                  for="upload-photo-cover"
                />
                <input
                  type="file"
                  name="houseHoldCover"
                  accept="image/*"
                  id="upload-photo-cover"
                  @change="onFileChange"
                  class="hidden"
                />
              </div>
            </div>
            <base-text-error :errorMessages="errorCover" />
          </label>
        </div>
        <div class="ImageHouseOwner">
          <p class="mt-3 font-bold required">Ảnh trang chủ hộ khẩu</p>
          <label for="upload-photo-houseowner">
            <div
              class="image-ID border border-dashed border-tertiary bg-secondary"
            >
              <img class="preview-image" id="image-owner" :src="imageOwner" />
              <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
                <img
                  src="@/assets/images/camera.png"
                  for="upload-photo-houseowner"
                />
                <input
                  type="file"
                  name="houseHoldOwner"
                  accept="image/*"
                  id="upload-photo-houseowner"
                  @change="onFileChange"
                  class="hidden"
                />
              </div>
            </div>
            <base-text-error :errorMessages="errorOwner" />
          </label>
        </div>
        <div class="ImageHousehold">
          <p class="mt-3 font-bold">Ảnh trang hộ khẩu có tên bạn</p>
          <p>(Nếu bạn là chủ hộ khẩu thì bỏ qua)</p>
          <label for="upload-photo-household">
            <div
              class="image-ID border border-dashed border-tertiary bg-secondary"
            >
              <img
                class="preview-image"
                id="image-household"
                :src="imageHouseHold"
              />
              <div class="absolute bottom-5 right-5 p-2 rounded-lg bg-white">
                <img
                  src="@/assets/images/camera.png"
                  for="upload-photo-household"
                />
                <input
                  type="file"
                  name="houseHold"
                  accept="image/*"
                  id="upload-photo-household"
                  @change="onFileChange"
                  class="hidden"
                />
              </div>
            </div>
          </label>
        </div>
      </form>
      <base-button
        class="w-full border-16 bg-tertiary text-white my-10 py-6"
        @click="handleSubmit"
        :loading="isLoading"
        >Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>
<script lang="ts">
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { defineComponent } from 'vue';
import { uploadHouseHold } from '@/apis/ApiEkyc';
import constRouter from '@/constants/constRouter';
import { Lead } from '@/apis/ApiLeadGen';
import { mapGetters } from 'vuex';
import { required } from '@/helpers/validate';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import { getContractId } from '@/helpers/localStorage';
export default defineComponent({
  components: { DefaultLayout, BaseButton, BaseTextError },
  name: 'BorrowerFamilyRegister',
  data() {
    return {
      imageCover: '',
      imageOwner: '',
      imageHouseHold: '',
      errorCover: '',
      errorOwner: '',
      errorHouseHold: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getExigency: 'getExigency',
      getUser: 'getUser',
      campaign: 'getCampaign',
    }),
  },
  created() {
    this.imageCover = this.getUser.houseHoldCoverImageUrl;
    this.imageOwner = this.getUser.houseHoldOwnerImageUrl;
    this.imageHouseHold = this.getUser.houseHoldImageUrl;
  },
  methods: {
    onFileChange(e: any) {
      const name = e.target.name;
      const file = e.target.files[0];
      if (name === 'houseHoldCover') {
        this.imageCover = URL.createObjectURL(file);
      }
      if (name === 'houseHoldOwner') {
        this.imageOwner = URL.createObjectURL(file);
      }
      if (name === 'houseHold') {
        this.imageHouseHold = URL.createObjectURL(file);
      }
    },
    validation() {
      this.errorCover = required('HouseHoldCover', this.imageCover);
      this.errorOwner = required('HouseHoldOwner', this.imageOwner);
      if (!this.imageCover || !this.imageOwner) {
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        const form: any = document.getElementById('form-household');
        const formdata = new FormData(form);
        this.isLoading = true;
        const { utmSource, utmCampaign, utmContent, clickId } = this.campaign;
        if (
          this.getUser.houseHoldCoverImageUrl &&
          this.getUser.houseHoldOwnerImageUrl
        ) {
          Lead({
            productName: 'easyLoan',
            contractId: this.getExigency.contractId || getContractId(),
            utmSource,
            utmCampaign,
            utmContent,
            clickId,
          });
          this.$router.push({
            name: constRouter.REGISTER_SUCCESS.name,
          });
        } else {
          uploadHouseHold(formdata)
            .then(() => {
              this.isLoading = false;
              Lead({
                productName: 'easyLoan',
                contractId: this.getExigency.contractId || getContractId(),
                utmSource,
                utmCampaign,
                utmContent,
                clickId,
              });
              this.$router.push({
                name: constRouter.REGISTER_SUCCESS.name,
              });
            })
            .catch(err => {
              this.isLoading = false;
              console.log(err);
            });
        }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.required::after {
  margin-left: 2px;
  content: '*';
  color: red;
}
.ImageCover,
.ImageHouseOwner,
.ImageHousehold {
  .image-ID {
    border-radius: 15px;
    position: relative;
    text-align: center;
    height: 300px;
    max-width: 300px;
    margin: auto;
    overflow: hidden;
    background-size: 100% 100%;

    &.selfie {
      max-height: 300px;
      height: 300px;

      .upload-selfie {
        position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
      }
    }

    .rolate-img {
      height: 320px !important;
      width: 200px;
      transform: rotate(-90deg) translateX(30%) translateY(15%);
    }
  }
}
</style>
